<template>
  <div class="flex items-center justify-center">
    <span
      class="h-3 w-3 mr-1 block rounded-full"
      :class="classObject.dot"
    ></span>
    <p :class="classObject.label">
      <b>{{ label }}</b>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      label: String,
    };
  },
  props: {
    status: Boolean | String,
    statusString: {
      type: String,
      default: "Status",
    },
  },
  computed: {
    classObject() {
      const classes = {
        label: [],
        dot: [],
      };
      const is_bool = typeof this.status === "boolean"
      switch (this.status) {
        case is_bool && true:
          classes.label = ["text-green-500"];
          classes.dot = ["bg-green-500"];
          this.label = "Finalizado";
          break;
        case is_bool && false:
          classes.label = ["text-yellow-300"];
          classes.dot = ["bg-yellow-300"];
          this.label = "Borrador";
          break;
        case "Oportuno":
          classes.label = ["text-green-500"];
          classes.dot = ["bg-green-500"];
          this.label = this.status;
          break;
        case "Fuera de término":
          classes.label = ["text-yellow-300"];
          classes.dot = ["bg-yellow-300"];
          this.label = this.status;
          break;
        case "Borrador":
          classes.label = ["text-yellow-300"];
          classes.dot = ["bg-yellow-300"];
          this.label = this.status;
          break;
        case "Finalizado":
          classes.label = ["text-green-300"];
          classes.dot = ["bg-green-300"];
          this.label = this.status;
          break;
        case "Seguimiento":
          classes.label = ["text-blue-300"];
          classes.dot = ["bg-blue-300"];
          this.label = this.status;
          break;
        case "Cerrado":
          classes.label = ["text-red-300"];
          classes.dot = ["bg-red-300"];
          this.label = this.status;
          break;
        default:
          classes.label = ["text-yellow-300"];
          classes.dot = ["bg-yellow-300"];
          this.label = this.status;
      }
      return classes;
    },
    classObjectOriginal: function () {
      let classes = {
        label: [],
        dot: [],
      };
      if (this.status === true) {
        classes.label = ["text-green-500"];
        classes.dot = ["bg-green-500"];
        this.label = "Finalizado";
      } else if (this.status === false) {
        classes.label = ["text-yellow-300"];
        classes.dot = ["bg-yellow-300"];
        this.label = "Borrador";
      } else if (this.status === 'Oportuno') {
        classes.label = ["text-green-500"];
        classes.dot = ["bg-green-500"];
        this.label = "Oportuno";
      } else {
        classes.label = ["text-yellow-300"];
        classes.dot = ["bg-yellow-300"];
        this.label = "Fuera de término";
      }
      return classes;
    },
  },
};
</script>