<template>
    <div>
        <CompanyReport/>
    </div>
</template>
<script>
import CompanyReport from "@/components/Dashboard/FCO/Reports/CompanyReport.vue";

export default{
    components: { CompanyReport }
}
</script>