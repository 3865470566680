<template>
  <div class="h-min-content xl:w-5/6 sm:w-full">
    <div v-if="loading" class="w-full mx-auto mt-7 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>

    <Accordion header="Informe" class="w-11/12" v-if="showRecommendations">
      <div v-if="!messageError && reportInfo">
        <div
          class="w-full py-2 rounded-xl border-blue-dark border-2 flex flex-col sm:flex-row justify-around items-center"
        >
          <div class="w-full py-2">
            <p><b class="text-blue-dark">Fecha</b></p>
            <p>
              <b>{{ reportInfo.created_at }}</b>
            </p>
          </div>
          <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"></span>
          <div class="w-full py-2">
            <p><b class="text-blue-dark">Estado</b></p>
            <ReportStatusLabel :status="reportInfo.status_boolean" />
          </div>
        </div>

        <h2 class="my-5 text-md mt-8 text-left">
          <b class="font-bold">Responsable:</b>
          {{ reportInfo.full_name }}
        </h2>
        <div class="flex justify-start py-4 gap-4">
          <a-button
            class=""
            type="primary"
            shape="round"
            ghost
            @click="complaintDetails"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </a-button>
          <a-button
            @click="openModal"
            :disabled="!completed"
            shape="round"
            type="primary"
            >Gestionar</a-button
          >
        </div>
      </div>
      <p class="text-center text-gray-500 text-lg" v-else>
        {{ messageError }}
      </p>
    </Accordion>
    <Modal v-if="showModal" size="4xl">
      <div class="relative">
        <h2 class="mb-3 text-2xl text-left text-blue-dark">
          <b>Informe {{ reportInfo.company.name_company }}</b>
        </h2>
        <a-icon
          type="plus"
          class="absolute bottom-3 right-0 text-3xl ml-auto transform text-black-600 rotate-45"
          @click.native="closeModal"
        />
      </div>
      <div class="flex flex-col gap-1 max-h-96 overflow-scroll">
        <p class="text-gray-500 font-bold text-left">
          Decisiones a favor del consumidor aceptadas por la entidad
        </p>
        <div class="w-1/2">
          <BaseInput
            numeric
            maxlength="5"
            label="Número"
            v-model="reportManagement.numberAccepted"
            :required="true"
          />
        </div>
        <BaseTextarea
          label="Descripción consolidada"
          rows="3"
          maxlength="2000"
          v-model="reportManagement.descriptionAccepted"
          required="true"
        />
        <DragDrop
          label="Carga de soportes"
          buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
          :fileList="reportManagement.fileAccepted"
          id="filesSupport1"
          @files-handler="setFilesSupport($event, 1)"
          required="true"
        />
        <p class="my-2 text-xs text-center text-gray-500">
          *Peso máximo de soportes 20MB
        </p>
        <p v-if="fileSize" class="text-sm text-center text-red-500">
          {{ fileSize }}
        </p>
        <p class="text-gray-500 font-bold text-left mt-5">
          Decisiones a favor del consumidor no aceptadas
        </p>
        <div class="w-1/2">
          <BaseInput
            numeric
            maxlength="5"
            label="Número"
            v-model="reportManagement.numberRejected"
          />
        </div>
        <BaseTextarea
          label="Descripción consolidada"
          rows="3"
          maxlength="2000"
          v-model="reportManagement.descriptionRejected"
        />
        <DragDrop
          label="Carga de soportes"
          buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
          :fileList="reportManagement.fileRejected"
          id="filesSupport2"
          @files-handler="setFilesSupport($event, 2)"
        />
        <p class="my-2 text-xs text-center text-gray-500">
          *Peso máximo de soportes 20MB
        </p>
        <p v-if="fileSize" class="text-sm text-center text-red-500">
          {{ fileSize }}
        </p>
        <p v-if="feedback" class="text-sm text-center text-red-500">
          {{ feedback }}
        </p>
        <div class="flex flex-row-reverse items-center gap-8 mt-12">
          <a-button
            id="submit"
            type="primary"
            html-type="submit"
            shape="round"
            class="w-1/3"
            :loading="loadingManagement"
            @click="setCompanyReport"
          >
            GUARDAR
          </a-button>
          <a-button
            class="w-1/3"
            type="danger"
            ghost
            shape="round"
            @click.native="closeModal"
          >
            CANCELAR
          </a-button>
        </div>
      </div>
    </Modal>

    <CompanyRecommendations v-if="showRecommendations" />

    <div
      class="flex flex-col gap-1 max-h-96 overflow-scroll"
      v-if="errorTypeUser"
    >
      <p class="text-gray-500 font-bold text-left">
        Módulo no habilitado para el DCF. Solo usuarios EV.
      </p>
    </div>
  </div>
</template>

<script>
import ReportStatusLabel from "@/components/Dashboard/FCO/FCOStatusLabel.vue";
import CompanyRecommendations from "@/components/Dashboard/FCO/Recommendations/CompanyRecommendations.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: {
    Accordion,
    ReportStatusLabel,
    CompanyRecommendations,
  },
  data() {
    return {
      showModal: false,
      accordionOpen: false,
      errorTypeUser: false,
      fileSize: "",
      showRecommendations: false,
      feedback: "",
      loading: false,
      messageError: "",
      completed: true,
      reportInfo: null,
      reportManagement: {
        numberAccepted: "",
        descriptionAccepted: "",
        fileAccepted: [],
        numberRejected: "",
        descriptionRejected: "",
        fileRejected: [],
      },
      loadingManagement: false,
    };
  },
  created() {
    this.getReportData();
  },
  methods: {
    async getReportData() {
      this.errorTypeUser = false;
      this.loading = true;
      let { data, error } = await this.$api.getCompanyReport();
      this.loading = false;
      if (error) {
        this.messageError = "Informe inexistente";
        if (error.code == 403) {
          this.showRecommendations = false;
          this.errorTypeUser = true;
        }
      } else if (data) {
        this.reportInfo = data.data.report;
        this.reportInfo.annual_report = data.data.company.annual_report;
        this.completed = data.data.completed;
        this.showRecommendations = true;
      }
    },
    setFilesSupport(files, id) {
      let filesSupport =
        id == 1
          ? this.reportManagement.fileAccepted
          : this.reportManagement.fileRejected;
      let size = this.checkFileSize([...files, ...filesSupport]);
      if (size <= 20) {
        filesSupport == this.reportManagement.fileAccepted
          ? (this.reportManagement.fileAccepted = files)
          : (this.reportManagement.fileRejected = files);
        this.fileSize = "";
      } else {
        this.fileSize =
          "El archivo de soporte que intenta cargar supera el limite previsto, por favor seleccione otro archivo que no supere dicho limite.";
      }
    },
    checkFileSize(files) {
      let size = 0;
      files.forEach((item) => {
        size += item.size;
      });
      size = size / (1024 * 1024);
      return size;
    },
    async setCompanyReport() {
      if (this.checkForm()) {
        this.loadingManagement = true;
        let form = {
          annual_report: this.reportInfo.annual_report.toString(),
          company_type: this.reportInfo.company_type.toString(),
          sfc_code: this.reportInfo.sfc_code.toString(),
          decisions_accepted_by_company: this.reportManagement.numberAccepted,
          description_accepted: this.reportManagement.descriptionAccepted,
          decisions_rejected_by_company: this.reportManagement.numberRejected,
          description_rejected: this.reportManagement.descriptionRejected,
          reference_file: "23",
        };

        let promises = [];
        promises.push(
          this.$api.createCompanyReport(
            form,
            this.reportManagement.fileAccepted
          )
        );
        form.reference_file = "24";
        promises.push(
          this.$api.createCompanyReport(
            form,
            this.reportManagement.fileRejected
          )
        );
        Promise.all(promises).then((response) => {
          if (response[0].data.code == 200 && response[1].data.code == 200) {
            this.showToast("success", "Informe guardado con éxito.");
            this.showModal = false;
            this.completed = false;
            this.loadingManagement = false;
          } else if (
            response[0].data.code == 500 &&
            response[1].data.code == 500
          ) {
            this.showToast(
              "error",
              `${response[0].data?.message} - ${response[1].data?.message}`
            );
            this.loadingManagement = false;
          }
        });
      }
    },
    checkForm() {
      if (
        this.reportManagement.numberAccepted == "" ||
        this.reportManagement.descriptionAccepted == "" ||
        this.reportManagement.fileAccepted.length <= 0 ||
        this.reportManagement.numberRejected == "" ||
        this.reportManagement.descriptionRejected == "" ||
        this.reportManagement.fileRejected.length <= 0
      ) {
        this.feedback = "Diligencie los campos obligatorios";
        return false;
      }

      this.feedback = "";
      return true;
    },
    complaintDetails() {
      this.$router.push({
        name: "companyReportView",
        query: {
          annual_report: this.reportInfo.annual_report,
          sfc_code: this.reportInfo.sfc_code,
          company_id: this.reportInfo.company.id_company,
        },
      });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
