<template>
  <div>
    <div v-if="loading" class="w-full mx-auto mt-7 left-0">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 64px"
            spin
          />
        </a-spin>
      </div>
    <Accordion
      subheader="Recomendación-"
      :header="recommendation.created_at | formatDateVue"
      v-for="(recommendation, idx) in recommendations"
      :key="idx"
      class="w-11/12 mt-6"
    >
      <div
        class="w-11/12 py-2 rounded-xl border-blue-dark border-2 flex flex-col sm:flex-row justify-around items-center"
      >
        <div class="w-full py-2">
          <p><b class="text-blue-dark">Tiempo límite</b></p>
          <p>
            <b>{{ recommendation.deadline }} días</b>
          </p>
        </div>
        <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"></span>
        <div class="w-full py-2">
          <p><b class="text-blue-dark">Estado</b></p>
          <ReportStatusLabel :status="recommendation.auto_status" />
        </div>
      </div>

      <h2 class="my-5 text-md mt-8 text-left">
        <b class="font-bold">Responsable:</b>
        {{ recommendation.full_name }}
      </h2>
      <div class="flex justify-start py-4 gap-4 w-11/12">
        <a-button class="w-1/3" type="primary" shape="round" @click="viewDetail(idx)" ghost>
          <a-icon
            type="eye"
            theme="twoTone"
            two-tone-color="#3366cc"
            :style="{
              fontSize: '20px',
            }"
          />
          <b class="my-auto ml-2">Ver</b>
        </a-button>
        <a-button
          v-if="recommendation.accepted == null"
          class="w-1/3"
          type="primary"
          shape="round"
          @click="openModal(idx, 0)"
          >Responder recomendación</a-button
        >
        <a-button
          v-if="recommendation.accepted && recommendation.status != 4"
          class="w-1/3"
          type="primary"
          shape="round"
          @click="openModal(idx, 1)"
          >Agregar plan de acción</a-button
        >
        <a-button
          v-if="recommendation.accepted && recommendation.status != 4"
          class="w-1/3"
          type="primary"
          shape="round"
          @click="getActionPlans(idx)"
          :loading="loadingReport"
          >Agregar reporte de avance</a-button
        >
      </div>
      <!-- DECISIÓN SOBRE LA RECOMENDACIÓN -->
      <Modal size="3xl" v-if="modals[idx].response">
        <div class="relative">
          <a-button
            icon="close"
            class="absolute border-none text-2xl bottom-3 right-0"
            @click="closeModal(idx, 0)"
          ></a-button>
          <h1
            class="text-center capitalize text-blue-dark font-bold text-2xl mb-3"
          >
            Recomendación {{ recommendation.created_at  | formatDateVue}}
          </h1>
        </div>
        <div class="overflow-scroll max-h-modal">
          <p class="text-gray-500 text-justify font-bold mt-2">
            Fecha de Reporte a la Junta Directiva (o su equivalente) por el
            Representante Legal de la entidad En cumplimiento de lo establecido
            en subnumeral 2.7.3.3. CBJ. Documentar y reportar a la junta
            directiva u órgano equivalente, los planes de acción implementados
            con ocasión de las recomendaciones efectuadas por el DCF en
            cualquier materia
          </p>

          <DragDrop
            label="Carga de soportes"
            buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
            :fileList="auxFiles"
            :id="idx"
            @files-handler="handleFilesResponse"
            required="true"
            max="20"
          />
          <h3 class="text-left text-xl font-bold mt-3 mb-4 text-gray-700">
            Planes de acción
          </h3>
          <p class="text-left text-gray-500 font-bold">
            Sí/No establece planes de acción con ocasión de la recomendación del DCF
          </p>
          <div class="flex gap-6 mt-3">
            <a-button
              class="w-1/5"
              type="primary"
              shape="round"
              ghost
              @click="recommendation.accepted = false"
              >No</a-button
            >
            <a-button
              class="w-1/5"
              type="primary"
              shape="round"
              @click="recommendation.accepted = true"
              >Sí</a-button
            >
          </div>
          <div :id="idx+'-justification'" v-if="recommendation.accepted == false">
            <BaseTextarea label="Justificación" rows="3" maxlength="1000" required="true" v-model="recommendation.justification"/>
          </div>
          <div
            class="flex gap-6 justify-center mt-5"
          >
            <a-button 
            type="primary"
            shape="round" 
            class="w-1/4" 
            @click="entityAnswer(idx)"
            :disabled="!(recommendation.accepted || recommendation.justification)" :loading="loadingButton"
              >GUARDAR CAMBIOS</a-button
            >
            <a-button type="danger" shape="round" ghost class="w-1/4" @click="closeModal(idx, 0)"  :loading="loadingButton"
              >CANCELAR</a-button
            >
          </div>
        </div>
      </Modal>
      <!-- PLANES DE ACCIÓN -->
      <Modal size="3xl" v-if="modals[idx].actionPlan">
        <div class="relative">
          <a-button
            icon="close"
            class="absolute border-none text-2xl bottom-3 right-0"
            @click="closeModal(idx, 1)"
          ></a-button>
          <h1
            class="text-center capitalize text-blue-dark font-bold text-2xl mb-3"
          >
            Recomendación {{ recommendation.created_at | formatDateVue}}
          </h1>
        </div>
        <div class="overflow-scroll max-h-modal">
          <div id="action-plan">
              <BaseTextarea
                required="true"
                label="Descripción del plan de acción"
                rows="3"
                maxlength="1000"
                v-model="descriptionPlanAction"
              />
              <DragDrop
                label="Carga de actividades"
                buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
                :fileList="filesActivities"
                :maxMB="20"
                :id="`action-plan-activity-${idx}`"
                @files-handler="handleGeneralActionPlan('activity', $event)"
                required="true"
                max="20"
              />
              <DragDrop
                label="Carga de responsables"
                :maxMB="20"
                buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
                :fileList="filesResponsibles"
                :id="`action-plan-responsible-${idx}`"
                @files-handler="handleGeneralActionPlan('responsible', $event)"
                required="true"
                max="20"
              />
              <DragDrop
                label="Carga de cronograma"
                buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
                :fileList="filesTimeline"
                :maxMB="20"
                :id="`action-plan-timeline-${idx}`"
                @files-handler="handleGeneralActionPlan('timeline', $event)"
                required="true"
                max="20"
              />
          </div>
          <div
            class="flex gap-6 justify-center mt-5"
          >
            <a-button type="primary" shape="round" class="w-1/4" @click="savePlanAction(idx)" :loading="loadingButton" :disabled="getInvalidatePlanAction"
              >GUARDAR</a-button
            >
            <a-button type="danger" shape="round" ghost class="w-1/4" @click="closeModal(idx, 1)" :loading="loadingButton"
              >CANCELAR</a-button
            >
          </div>
        </div>
      </Modal>
      <!-- REPORTE DE AVANCES -->
      <Modal size="4xl" v-if="modals[idx].advanceReport">
        <div class="relative">
          <a-button
            icon="close"
            class="absolute border-none text-2xl bottom-3 right-0"
            @click="closeModal(idx, 2)"
          ></a-button>
          <h1
            class="text-center capitalize text-blue-dark font-bold text-2xl mb-3"
          >
            Reporte de avance
          </h1>
          <p v-if="!idActionPlan">
            Selecciona el plan de acción sobre el cual vas a registrar el avance:
          </p>
        </div>
        <div class="max-w-4xl justify-center items-center" v-if="!idActionPlan">
        <a-table
            class="border rounded-xl my-6"
            :columns="modalColumns"
            rowKey="id"
            :data-source="action_plans"
            :pagination="false"
            :scroll="{ x: 0, y: 300 }"
        >
          >
          <span
              slot="actions"
              slot-scope="text, record"
              class="mr-4 text-sm font-medium text-right whitespace-nowrap flex"
          >
          <a-button
              icon="right"
              size="small"
              @click="idActionPlan = record.id"
          ></a-button>
        </span>
        </a-table>
      </div>
        <div class="max-h-modal overflow-scroll flex gap-3 flex-col" v-if="idActionPlan">
          <Accordion
            header="Informe donde conste la Evaluación de la implementación de las recomendaciones que realice el DCF."
          >
            <BaseTextarea label="Descripción consolidada" rows="3" maxlength="1000" required="true" v-model="descriptionEvaluation"/>
            <DragDrop
              label="Carga de soportes"
              buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
              :fileList="filesEvaluation"
              :id="`advanceReport-1${idx}`"
              @files-handler="handleGeneralProgressReport('evaluation', $event)"
              required="true"
              max="20"
            /> </Accordion
          ><Accordion
            header="Informe a asamblea general ordinaria u órgano equivalente, sobre las actuaciones adelantadas frente a las recomendaciones o propuestas del DCF, el plan de acción implementado por la entidad, si lo hay, y los casos y asuntos que el DCF haya solicitado llevar a conocimiento de la junta u órgano equivalente.
"
          >
            <BaseTextarea label="Descripción consolidada" rows="3" maxlength="1000" required="true" v-model="descriptionAssembly"/>
            <DragDrop
              label="Carga de soportes"
              buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
              :fileList="filesAssembly"
              :id="`advanceReport-2${idx}`"
              @files-handler="handleGeneralProgressReport('assembly', $event)"
              required="true"
              max="20"
            /> </Accordion
          ><Accordion
            header="Informe de seguimiento permanente a la gestión del DCF presentado semestralmente a la junta directiva u órgano equivalente, con los resultados y planes de acción implementados para la mejora continua de la atención al consumidor financiero, la mitigación de la causa raíz de las quejas o reclamos, así como el adecuado funcionamiento de la institución del DCF. 
"
          >
            <BaseTextarea label="Descripción consolidada" rows="3" maxlength="1000" required="true" v-model="descriptionTracing"/>
            <DragDrop
              label="Carga de soportes"
              buttonLabel="SELECCIONE ARCHIVOS DE SOPORTE"
              :fileList="filesTracing"
              :id="`advanceReport-3${idx}`"
              @files-handler="handleGeneralProgressReport('tracing', $event)"
              required="true"
              max="20"
            />
          </Accordion>
          <div class="flex gap-6 items-center justify-center mt-4">
            <a-button class="w-1/3" type="primary" shape="round" :loading="loadingButton" :disabled="getInvalidateReport" @click="saveProgressReport(idx)">GUARDAR</a-button>
            <a-button class="w-1/3"
              type="danger"
              shape="round"
              ghost
              :loading="loadingButton"
              @click="closeModal(idx, 2)"
              >CANCELAR</a-button
            >
          </div>
        </div>
      </Modal>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import ReportStatusLabel from "@/components/Dashboard/FCO/FCOStatusLabel.vue";
import checkFileSize from "@/utils/checkFileSize";
const modalColumns = [
  {
    title: "Fecha de subida",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Concepto",
    dataIndex: "concept",
    key: "concept",
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: { Accordion,  ReportStatusLabel},
  data() {
    return {
      // Recomendaciones
      recommendations: [],
      modals: [],
      filesResponse: [],
      auxFiles: [],
      // Planes de acción
      filesActivities: [],
      filesResponsibles: [],
      filesTimeline: [],
      descriptionPlanAction: null,
      // Reportes de avance
      filesEvaluation: [],
      filesAssembly: [],
      filesTracing: [],
      descriptionEvaluation: null,
      descriptionAssembly: null,
      descriptionTracing: null,
      action_plans: [],
      idActionPlan: null,
      // General
      modalColumns: modalColumns,
      loadingReport: false,
      loading: false,
      loadingButton: false,
      msgFile: "Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información. Si requiere subir más anexos lo puede hacer con el número de radicado en el seguimiento/búsqueda de queja",

    };
  },
  async created() {
    await this.getRecommendationByCompany();
  },
  methods: {
    // Peticiones al servidor
    async getRecommendationByCompany() {
      this.loading = true;
      let { error, data } = await this.$api.getRecommendationByCompany();
      this.loading = false;
      if (error) {
        this.showToast(
          "info",
          "Error consultando las recomendaciones."
        );
        return
      }
      if (data) {
        this.recommendations = data.results;
        this.modals = Array.apply(null, Array(data.results.length)).map(function() { return { response: false, actionPlan: false, advanceReport: false }});
        this.filesResponse = Array(data.results.length).map(function() { return []});
      }
    },
    // Responder a la recomendación
    async entityAnswer(idx) {
      const recommendation = this.recommendations[idx];
      this.loadingButton = true;
      let { error, data } = await this.$api.entityAnswer(recommendation.id,
      { form: {accepted: recommendation.accepted, justification: recommendation.justification || '' }, files: this.auxFiles });
      this.loadingButton = false;
      if (error) {
        this.showToast('error', 'Error guardando respuesta, verifique los anexos.')
        return;
      }
      if (data) {       
        this.showToast('success', 'Respuesta guardada correctamente.') 
        this.closeModal(idx, 0, false);
        if (recommendation.accepted) this.openModal(idx, 1);
      }
    },
    // Manejo de archivos de respuesta
    handleFilesResponse(files) {
      if (checkFileSize([...files, ...this.auxFiles], 30)) {
        this.showToast('error', this.msgFile)
      } else {
        this.auxFiles = files;
        this.feedback = "";
      }
    },
    // Manejo de planes de acción
    handleGeneralActionPlan(nameFile, files) {
      if (nameFile == 'activity') this.filesActivities = files
      else if (nameFile == 'responsible') this.filesResponsibles = files;
      else if (nameFile == 'timeline') this.filesTimeline = files;
    },
    async savePlanAction(idx) {
      const recommendation = this.recommendations[idx];
      this.loadingButton = true;
      let { error, data } = await this.$api.addActionPlan(recommendation.id, { form: {description: this.descriptionPlanAction }, files: this.filesTimeline });
      
      if (error) {
        this.showToast('error', 'Error guardando el plan acción con los anexos de cronograma.')
        this.loadingButton = false;
        return;
      }
      const idActionPlan = data.id;

      await this.$api.addFilesToActionPlan(recommendation.id, idActionPlan, 2, { form: {description: this.descriptionPlanAction }, files: this.filesResponsibles});
      
      await this.$api.addFilesToActionPlan(recommendation.id, idActionPlan, 3, { form: {description: this.descriptionPlanAction }, files: this.filesActivities});
      this.loadingButton = false;
      if (data) {        
        this.showToast('success', 'Plan de acción guardado correctamente.') 
        this.closeModal(idx, 1);
      }
    },
    // Traer planes de acción para el reporte de avances
    async getActionPlans(idx) {
      const recommendation = this.recommendations[idx];
      this.loadingReport = true;
      let { error, data } = await this.$api.getActionPlans(recommendation.id);
      if (error) {
        this.showToast('error', 'Error recuperando los planes de acción para la recomendación seleccionada.')
        this.loadingReport = false;
        return;
      }
      this.action_plans = data.results?.map((source, index) => {
          return {
            ...source,
            concept: `Plan de acción ${index+1}`,
          }
        });
      this.loadingReport = false;
      this.openModal(idx, 2);

    },
    async saveProgressReport(idx) {
      const recommendation = this.recommendations[idx];
      this.loadingButton = true;
      let { error, data } = await this.$api.progressReport(recommendation.id,
      { form: {evaluation_report: this.descriptionEvaluation, assembly_report: this.descriptionAssembly, monitoring_report: this.descriptionTracing, action_plan: this.idActionPlan+'' }, files: this.filesEvaluation });
      
      if (error) {
        this.showToast('error', 'Error guardando el reporte de avance con los anexos de evaluación.')
        this.loadingButton = false;
        return;
      }
      const idProgressReport = data.id;

      await this.$api.addFilesToProgressReport(data.action_plan, idProgressReport, 2,
      { form: {evaluation_report: this.descriptionEvaluation, assembly_report: this.descriptionAssembly, monitoring_report: this.descriptionTracing}, files: this.filesAssembly});
      
      await this.$api.addFilesToProgressReport(data.action_plan, idProgressReport, 3,
      { form: {evaluation_report: this.descriptionEvaluation, assembly_report: this.descriptionAssembly, monitoring_report: this.descriptionTracing}, files: this.filesTracing});
      this.loadingButton = false;
      if (data) {        
        this.showToast('success', 'Reporte de avance guardado correctamente.') 
        this.closeModal(idx, 2);
      }
    },
    handleGeneralProgressReport(nameFile, files) {
      if (nameFile == 'evaluation') this.filesEvaluation = files
      else if (nameFile == 'assembly') this.filesAssembly = files;
      else if (nameFile == 'tracing') this.filesTracing = files;
    },
    // Traer toda la información de una recomendación
    async viewDetail(idx) {
      const recommendation = this.recommendations[idx];
      this.$router.push({
        path: "company_report/recommendation",
        query: {
          recommendation_code: recommendation.id,
          sfc_code: recommendation.sfc_code,
          company_type: recommendation.company_type,
          id_company: recommendation.company_id,
        },
      });
    },
    openModal(recommendation, modal) {
      if (modal === 0) {
        // Respuesta de parte de la entidad a la recomendación
        this.auxFiles = [];
        this.filesResponse = [];
        this.modals[recommendation].response = true;
      } else if (modal === 1) {
        // Manejo de los planes de acción
        // Limpieza de los formularios
        this.filesActivities = [];
        this.filesResponsibles = [];
        this.filesTimeline = [];
        this.descriptionPlanAction = null;
        this.modals[recommendation].actionPlan = true;
      }else {
        // Manejo de los reportes de avance
        // Limpieza de formularios
        this.idActionPlan = null;
        this.filesEvaluation = [];
        this.filesAssembly = [];
        this.filesTracing = [];
        this.descriptionEvaluation = null;
        this.descriptionAssembly = null;
        this.descriptionTracing = null;
        this.modals[recommendation].advanceReport = true;
      }
    },
    closeModal(recommendation, modal, reset = true) {
      if (modal === 0) {
        if (reset) this.recommendations[recommendation].accepted = null; 
        this.modals[recommendation].response = false; }
      else if (modal === 1) this.modals[recommendation].actionPlan = false;
      else this.modals[recommendation].advanceReport = false;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    getInvalidatePlanAction: function () {
      if (!this.descriptionPlanAction) return true;
      if (this.filesActivities.length<1) return true;
      if (this.filesResponsibles.length<1) return true;
      if (this.filesTimeline.length<1) return true;
      return false
    },
    
    getInvalidateReport: function () {
      if (!this.descriptionEvaluation) return true;
      if (!this.descriptionAssembly) return true;
      if (!this.descriptionTracing) return true;
      if (this.filesEvaluation.length<1) return true;
      if (this.filesAssembly.length<1) return true;
      if (this.filesTracing.length<1) return true;
      return false
    },
  }
};
</script>
<style>
.max-h-modal {
  max-height: 50em;
}
</style>
